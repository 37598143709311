import type { AccessDeniedCollection } from "../_model";
import { warn } from "../const";

export function initAccessDenied(): AccessDeniedCollection {
  return {
    localstorageDenied: isLocalStorageDenied(),
  };
}

function isLocalStorageDenied() {
  try {
    localStorage.length;
  } catch (e: any) {
    if (e.name === "SecurityError") {
      warn("access denied to localstorage!!");
      return true;
    }
  }
  return false;
}

// function isCookiesDenied() {
//   try {
//     document.cookie = "asd=asd";
//   } catch (e: any) {
//     warn("access denied to cookies!!");
//     if (e.name === "SecurityError") {
//       return true;
//     }
//   }
//   return false;
// }
